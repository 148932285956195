<template>
    <section>
        <div class='narrowBox' v-if='$route.path.endsWith("expired")'>
            <h1>מישהו כבר השתמש בקישור הזה</h1>
            <div>
                <div>אם זה לא אתם, אנא עדכנו אותנו באופן מיידי כדי שנוכל לחסום את החשבון ולייצר לכם חשבון חדש.</div>
                <div>
                    כבר ביצעתם את תהליך ההרשמה?
                    <button @click='$auth0.loginWithRedirect()'>לחצו להתחברות</button>
                </div>
            </div>
        </div>
        <div class='narrowBox' v-else-if='$route.path.endsWith("completed")'>
            <h1>ההרשמה הושלמה בהצלחה!</h1>
            <div>
                <button @click='$auth0.loginWithRedirect()'>להתחברות למערכת</button>
            </div>
        </div>
        <div class='narrowBox' v-else>
            <h1>יש בעיה בקישור שלכם</h1>
            <div>
                צרו קשר עם יוצר החשבון שלכם, או שלחו לנו מייל
                ל-<a href='mailto:sharedchart.care@gmail.com'>sharedchart.care@gmail.com</a>,
                ונעזור לכם לפתור את הבעיה.
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .narrowBox {
        margin-top: 4rem;
        width: 24rem;
        text-align: center;

        & > div {
            padding: 1rem;

            & > div:first-child {
                margin-bottom: 2rem;
            }

            & > button:first-child {
                margin: 2rem auto;
            }
        }

        & button {
            border-radius: $corner;
            padding: .5rem 1rem;
            background-color: $swatchB;
            @include shadow;
            margin-top: .5rem;

            &:hover {
                background-color: $hoverB;
            }
        }
    }
</style>